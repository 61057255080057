/*
.sideBar {
  margin-bottom: 10px;
}
.sideBarItem {
  display: inline;
  padding: 10px;
}
*/
.layout-container {
  padding: 15px;
}
.sideBarItem {
  margin: 5px;
  padding: 5px;
  border-style: dashed;
  border-color: gainsboro;
}
.arrangement-container {
  margin-top: 5px;
  padding: 20px 0px 20px 10px;
  border-top-style: dashed;
  border-top-color: cadetblue;
  border-bottom-style: dashed;
  border-bottom-color: cadetblue;
  border-left-style: dashed;
  border-left-color: cadetblue;
}
.arrangement {
  padding: 5px 0px 5px 5px;
  border-top-style: solid;
  border-top-color: cadetblue;
  border-bottom-style: solid;
  border-bottom-color: cadetblue;
  border-left-style: solid;
  border-left-color: cadetblue;
}
.arrangement-header > div {
  display: inline;
}