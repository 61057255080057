#base-onto-selector-label {
  height: 20px;
  font-size: 14pt;
  text-align: right;
}

.dx-fieldset {
  margin-left: 0px;
  margin-right: 0px;
}

.current-value {
  font-weight: bold;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 260px;
}

.caption {
  font-weight: 500;
  font-size: 18px;
}

.option {
  margin-top: 10px;
}